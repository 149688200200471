import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loader from './Loader/Loader';
// import Shaolin from './Shaolin';



// const MarkdownPreview = lazy(() => import('./zmb'));

const Matas = lazy(() => import('./Shaolin'));

const root = ReactDOM.createRoot(document.querySelector('.intelligence_adoption____wrapper_Pkjhgyuio___'));
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loader />}>
      <Matas />
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();